import React, { FC } from 'react';
import { graphql } from 'gatsby';

import SectionName from 'common/SectionName';
import SectionTitle from 'common/SectionTitle';
import { Container, Layout } from 'layout';
import Image from 'common/Image';
import SearchBar from 'common/SearchBar';
import { NotFoundPageProps } from './models';

import './404.scss';

const NotFoundPage: FC<NotFoundPageProps> = ({
  data: {
    notFoundPage: {
      pageTitle,
      headline,
      searchInfo,
      searchPlaceholder,
      image,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoNoIndex,
    },
  },
}) => (
  <Layout seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoNoIndex }}>
    <section className="not-found container-wrapper">
      <div className="not-found__holder">
        <Container fluid className="not-found__content">
          <div className="not-found__content-text">
            <SectionName text={pageTitle} />
            <SectionTitle text={headline} />
            <p className="not-found__search-info">{searchInfo}</p>
            <SearchBar className="not-found__search-bar" text={searchPlaceholder} showInput />
          </div>
          <Image imageData={image} alt={pageTitle} className="not-found__img" />
        </Container>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query {
    notFoundPage {
      pageTitle
      headline
      searchInfo
      searchPlaceholder
      image {
        ...ImageFragment
      }
      ...SeoFragment
    }
  }
`;

export default NotFoundPage;
